import { getIndustry ,createIndudryApi,updateIndustryApi} from "../../api/IndustryApi";
import { displayErrorToast, displaySuccessToast } from "../../helper/toast_notification_function"
import {
  saveCategories,
  startGettingCategoriesData,
} from "../constants/categories_constants";
export const getAllIndustry = (dispatch, token, navigate) => {
  dispatch({
    type: startGettingCategoriesData,
  });
  getIndustry(token, dispatch, navigate).then((e) => {
    return dispatch({
      type: saveCategories,
      payload: e.data.data ? e.data.data : [],
    });
  });
};




export const createIndustry = (data, token, dispatch ,navigate) => {
    createIndudryApi(data, token, dispatch ,navigate)
      .then((res) => {
          displaySuccessToast(`Industry Created successfully!`)
          getAllIndustry(dispatch,token,navigate)
        })
        .catch(error => {
          displayErrorToast(error)
        })
  }
  

  export const updateIndusry = (data, token, dispatch ,navigate) => {
    updateIndustryApi(data, token, dispatch ,navigate)
      .then((res) => {
          displaySuccessToast(`Industry Updated successfully!`)
          getAllIndustry(dispatch,token,navigate)
        })
        .catch(error => {
          displayErrorToast(error)
        })
  }
  

