import { apiHandle } from "./apiHandler";
import { dataWithFiles } from '../helper/common_functions'

// Get All Categories Api
export const getIndustry = async (token, dispatch, navigate) => {
  return await apiHandle(token, dispatch, navigate).get("/auth/industry");
};



export const createIndudryApi = async (data, token, dispatch, navigate) => {
  return await apiHandle(token, dispatch, navigate).post('/auth/industry', dataWithFiles(data))
}
// Update Category Api
export const updateIndustryApi = async (data, token, dispatch, navigate) => {
  return await apiHandle(token, dispatch, navigate).put(`/auth/industry/${data.id}`, dataWithFiles(data))
}
