import { saveBooking,startGettingBookingData,SaveBookingDiscountType } from "../../constants/Booking_constants"
const INITIAL_STATE = {
    isLoading:true,
    booking:[],
    bookingDiscountType:[]
}
const BookingReducer = (state = INITIAL_STATE,action) => {
    const {type, payload} = action
    switch (type) {
        case saveBooking:
            return {
                ...state,
                isLoading:false,
                booking:payload
            }
        case SaveBookingDiscountType:
            return {
                ...state,
                isLoading:false,
                bookingDiscountType:payload
                }
        case startGettingBookingData:
            return {
                ...state,
                isLoading:true,
            }
        default:
            return state
    }
}
export default BookingReducer