import { getCompany, GetCompanyProfileApi } from "../../api/CompanyApi";
import {
  saveJobOpportunities,
  startGettingJobOpportunitiesData,
  GetAttendence,
  Clear_Attendence,
} from "../constants/job_opportunities_constants";

export const getAllJobOpportunities = (dispatch, token, navigate) => {
  dispatch({
    type: startGettingJobOpportunitiesData,
  });
  getCompany(token, dispatch, navigate).then((e) => {
    return dispatch({
      type: saveJobOpportunities,
      payload: e.data.data ? e.data.data : [],
    });
  });
};

export const getCompanyProfile = (dispatch, token, navigate, id) => {
  GetCompanyProfileApi(token, dispatch, navigate, id).then((e) => {
    return dispatch({
      type: GetAttendence,
      payload: e.data.data ? e.data.data[0] : [],
    });
  });
};
export const ClearCompanyProfile = (dispatch) => {
  return dispatch({ type: Clear_Attendence });
};
