import { logoutUserType } from "../../constants/common_constants";
import {
  saveTags,
  startGettingTagsData,
  SaveByIdContact
} from "../../constants/tags_constants";

const INITIAL_STATE = {
  isLoading: true,
  tags: [],
  isLoadingContact: true,
  contact: null,
};
const tagsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case SaveByIdContact:
      return {
        ...state,
        isLoadingContact: false,
        contact: payload,
      };
      case saveTags:
      return {
        ...state,
        isLoading: false,
        tags: payload,
      };
    case startGettingTagsData:
      return {
        ...state,
        isLoadingContact:true,
        isLoading: true,
      };
    case logoutUserType:
      return {
        ...state,
        tags: [],
        isLoading: true,
      };
    default:
      return state;
  }
};

export default tagsReducer;
