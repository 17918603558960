import * as React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import { MdOutlineClose } from 'react-icons/md'
import { ToastContainer } from 'react-toastify'
import {
  TextField
} from '@mui/material'
import { allStyles } from '../../allStyles'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
// import { createCategory, updateCategory } from '../../redux/actions/IndustryAction'
import { updateIndusry, createIndustry } from '../../redux/actions/IndustryAction'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}))

const BootstrapDialogTitle = props => {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <MdOutlineClose />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired
}

export const AddUpdateIndustry = ({ open, closeClickHandle, editState }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [ImgUpdate, setImgUpdate] = React.useState("")
  const [formData, setformData] = React.useState({})
  const [submitButtonDisabled, setSubmitButtonDisabled] = React.useState(true)

  React.useEffect(() => {
    setSubmitButtonDisabled(true)
  }, [editState])

  const accessToken = useSelector(
    state => state.userTokensReducer.tokens.accessToken
  )

  const closeHandle = () => {
    setSubmitButtonDisabled(true)
    setImgUpdate("")
    closeClickHandle()
  }

  const submitDataHandle = e => {
    e.preventDefault()
    if (editState) {
      formData.id = editState.id
      updateIndusry(formData, accessToken, dispatch, navigate)
      closeClickHandle()
      setformData({})
    } else {
      createIndustry(formData, accessToken, dispatch, navigate)
      closeClickHandle()
      setformData({})
    }
  }
  const onChangeHandle = e => {
    let name = e.target.name
    let val = e.target
    if (name === 'thumbnail') {
      setformData({ ...formData, [e.target.name]: val.files[0] })
      setImgUpdate(URL.createObjectURL(val.files[0]))
    } else {
      setformData({ ...formData, [name]: val.value })
    }
    setSubmitButtonDisabled(false)
  }

  React.useEffect(() => {
    if (editState) {
      setImgUpdate(process.env.REACT_APP_ADMIN_IMAGE + editState.thumbnail)
    }
  }, [editState])

  return (
    <div>
      <BootstrapDialog aria-labelledby='customized-dialog-title' open={open}>
        <BootstrapDialogTitle
          id='customized-dialog-title'
          onClose={closeHandle}
        >
          {editState ? 'Update Industry' : 'Add Industry'}
        </BootstrapDialogTitle>
        <form
          autoComplete='off'
          encType='multipart/form-data'
          onSubmit={e => submitDataHandle(e)}
        >
          <DialogContent dividers sx={{ maxHeight: '700px' }}>
            <TextField
              defaultValue={editState ? editState.title : ''}
              name='title'
              // value={Price}
              sx={allStyles.modalFields}
              label='Title'
              type='text'
              variant='outlined'
              fullWidth
              onChange={e => onChangeHandle(e)}
              required
            />
            {
              ImgUpdate ? <img src={ImgUpdate} style={{ width: "200px", height: "180px", objectFit: "contain" }} /> : ""
            }
            <TextField
              name='thumbnail'
              // value={Price}`

              sx={allStyles.modalFields}
              type='file'
              accept='.jpg, .jpeg, .png'
              fullWidth
              variant='outlined'
              InputLabelProps={{
                shrink: true
              }}
              helperText='Choose image to upload (PNG, JPG)'
              onChange={e => onChangeHandle(e)}
              required={!ImgUpdate}
            />
          </DialogContent>
          <DialogActions>
            <Button

              disabled={submitButtonDisabled}
              type='submit'
              sx={{ ...allStyles.actionsButtons, mx: 2, my: 1 }}
              variant='outlined'
            >
              {editState ? 'Update Industry' : 'Create Industry'}
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
      <ToastContainer />
    </div>
  )
}
