import { savepromo_Code,startGettingpromo_CodeData } from "../../constants/promo_Constants"
const INITIAL_STATE = {
    isLoading:true,
    promoCode:[],
}
const PromoReducer = (state = INITIAL_STATE,action) => {
    const {type, payload} = action
    switch (type) {
        case savepromo_Code:
            return {
                ...state,
                isLoading:false,
                promoCode:payload
            }
        case startGettingpromo_CodeData:
            return {
                ...state,
                isLoading:true,
            }
        default:
            return state
    }
}
export default PromoReducer