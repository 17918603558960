
export const dataWithFiles = (data) => {
    const formDataWithFiles = data.Image !== '' || data.AttachmentType === 'image' ? new FormData() : data
  
          if (formDataWithFiles) {
            // axios will automatically set the content-type to multipart/form-data if the
            // data param is a FormData object
            // otherwise, it will use application/json
            // (study the Dev Tools > Network tab > XHR tab headers)
            Object.keys(data).forEach(field =>
              formDataWithFiles.append(field, data[field])
            )
          }
  
  
          return formDataWithFiles
  }