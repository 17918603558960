import React, { useEffect, useState } from "react";
import logo from "../assets/images/logo/logo.png";
import {
  Paper,
  Stack,
  OutlinedInput,
  TextField,
  FormControl,
  InputLabel,
  Button,
  CircularProgress,
  FilledInput,
  InputAdornment,
  Typography,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { allStyles } from "../allStyles";
import { useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import { saveTokens, userState } from "../redux/actions/userActions";
import { ToastContainer } from "react-toastify";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../helper/toast_notification_function";
export const Login = () => {
  const [visiable, SetVisiable] = useState();
  const dispatch = useDispatch();
  const [loginData, setLoginData] = useState({});
  const [processLoading, setProcessLoading] = useState(false);

  const handleClickShowPassword = () => {
    SetVisiable(!visiable);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // const state =  useSelector(state=>state)

  // let onChangeHandle = (e) => {

  //   let id =e.target.id
  //   let value = e.target.value

  //   setLoginData({...loginData,id,value})

  // }

  let login = (e) => {
    e.preventDefault();
    if (!loginData.email || !loginData.password) {
      displayErrorToast("Please fill the input fields!");
    } else {
      setProcessLoading(true);
      axios
        .post(process.env.REACT_APP_ADMIN_LOGIN_URL, { ...loginData, ttl: "1m" })
        .then(async (res) => {
          displaySuccessToast("Logged in Successfully!");
          if (res.data.success) {
            const { accessToken, refreshToken } = await res?.data?.data?.[0];
            localStorage.setItem("refreshToken", await refreshToken);
            localStorage.setItem("accessToken", await accessToken);
            let tokens = {
              accessToken: await accessToken,
              refreshToken: await refreshToken,
            };
            saveTokens(dispatch, tokens);
            setProcessLoading(false);
            e.target.reset();
            userState(dispatch, true);
          }
        })
        .catch((error) => {
          displayErrorToast(error);
          setProcessLoading(false);
        });
    }
  };

  useEffect(() => {
    setLoginData({});
  }, []);

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      direction="column"
      sx={allStyles.loginPage}
    >
      <Paper
        square
        elevation={2}
        children={
          <Stack
            sx={allStyles.loginBox}
            alignItems="center"
            justifyContent="center"
          >
            <Stack
              alignItems="center"
              spacing={3}
              direction="column"
              component="form"
              sx={{
                "& > :not(style)": { width: "35ch" },
                px: 4,
              }}
              noValidate
              autoComplete="off"
              onSubmit={login}
            >
              <Stack alignItems="center">
                <img width="170px" src={logo} />
                <Typography variant="h6">Investor Presentations</Typography>
              </Stack>
              <Stack spacing={3}>
                <Stack spacing={2}>
                  <TextField
                    fullWidth
                    // id="outlined-basic"
                    type="text"
                    id="LoginKey"
                    label="User Name"
                    variant="outlined"
                    onChange={(e) =>
                      setLoginData({ ...loginData, email: e.target.value })
                    }
                  />
                  <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">
                      Password
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      fullWidth
                      type={visiable ? "text" : "password"}
                      onChange={(e) =>
                        setLoginData({ ...loginData, password: e.target.value })
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {visiable ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                  </FormControl>
                </Stack>
                <Stack
                  direction="row"
                  sx={{ my: 3, position: "relative" }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Button
                    type="submit"
                    disabled={processLoading}
                    sx={allStyles.loginButton}
                    variant="outlined"
                    color="primary"
                    size="large"
                  >
                    {processLoading ? "" : "Log in"}
                  </Button>
                  {processLoading ? (
                    <Stack sx={{ position: "absolute" }}>
                      {" "}
                      <CircularProgress size={20} disableShrink />
                    </Stack>
                  ) : null}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        }
      />

      <ToastContainer />
    </Stack>
  );
};
