import { Skeleton, Stack } from "@mui/material";
import React from "react";

export const PaginationSkeleton = () => {
  return (
    <Stack
      sx={{ my: 2, px: 2 }}
      justifyContent="end"
      spacing={3}
      direction="row"
    >
      <Skeleton width={100} />
      <Skeleton width={50} />
      <Skeleton width={80} />
      <Skeleton width={20} />
      <Skeleton width={20} />
    </Stack>
  );
};
