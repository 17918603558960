import { logoutUserType } from "../../constants/common_constants";
import {
  deleteEventType,
  saveEvents,
  startGettingEventsData,
  GetUserProfile,
  ClearUserProfile,
} from "../../constants/events_constants";
const INITIAL_STATE = {
  get_Profile: [],
  isLoading_profile: true,
  isLoading: true,
  events: [],
};

const eventsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case saveEvents:
      return {
        ...state,
        isLoading: false,
        events: payload,
      };

    case startGettingEventsData:
      return {
        ...state,
        isLoading: true,
      };

    case ClearUserProfile:
      return {
        ...state,
        isLoading_profile: true,
      };

    case GetUserProfile:
      return {
        ...state,
        isLoading_profile: false,
        get_Profile: payload,
      };

    case logoutUserType:
      return {
        ...state,
        events: [],
        isLoading: true,
      };

    case deleteEventType:
      let newDataArray = state.events.filter(
        (e, i) => e.PKEventId !== payload && e.isDeleted !== true
      );
      return {
        ...state,
        events: newDataArray,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default eventsReducer;
