export const saveJobOpportunities = 'SAVE_JOB_OPPORTUNITIES'
export const startGettingJobOpportunitiesData = 'START_GETTING_JOB_OPPORTUNITIES_DATA'

export const createdNewJobOpportunityType = 'CREATED_NEW_JOB_OPPORTUNITY_TYPE'
export const updateJobOpportunityType = 'UPDATE_JOB_OPPORTUNITY_TYPE'
export const deleteJobOpportunityType = 'DELETE_JOB_OPPORTUNITY_TYPE'


export const GetAttendence='GET_ATTENDENCE'
export const Clear_Attendence="CLEAR_ATTENDENCE"

