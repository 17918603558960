import { apiHandle } from "./apiHandler";
// Get All Events Api
export const getAllUserApi = async (token, dispatch, navigate, data_param) => {
  return await apiHandle(token, dispatch, navigate).get("/auth/users", {
    params: { level_two_profile_status: data_param },
  });
};

export const UpdateLevelStatus = (token, dispatch, navigate, data_param) => {
  return apiHandle(token, dispatch, navigate).put(
    `/auth/users/levelTwoProfile/status/${data_param.id}`,
    data_param
  );
};

export const GetUserProfile_api = async (token, dispatch, navigate, data) => {
  return await apiHandle(token, dispatch, navigate).get(
    `/auth/users/fullProfile/${data}`
  );
};
