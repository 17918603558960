import { saveFaq,startGettingFaqData } from "../../constants/Faq_constants"

const INITIAL_STATE = {
    isLoading:true,
    faq:[]
}



const FaqReducer = (state = INITIAL_STATE,action) => {
    const {type, payload} = action
    switch (type) {
        case saveFaq:
            return {
                ...state,
                isLoading:false,
                faq:payload
            }
        case startGettingFaqData:
            return {
                ...state,
                isLoading:true,
            }
        default:
            return state
    }
}


export default FaqReducer
