import React, { useEffect, useState } from "react";
import {
  Box,
  Stack,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  NativeSelect,
  TableContainer,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableRow,
  TableHead,
  Paper,
  TablePagination,
  IconButton,
  Button,
} from "@mui/material";
import { PaginationSkeleton } from "../../assets/PaginationSkeleton";
import { RefressButton } from "../../assets/buttons/RefressButton";
import { AiOutlineUser } from "react-icons/ai";
import { AddButton } from "../../assets/buttons/AddButton";
import { useDispatch, useSelector } from "react-redux";
import { RiEdit2Line } from "react-icons/ri";
import { NoDataSetMessage } from "../../components/NoDataSetMessage";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { TableBodyDataSkeleton } from "../../assets/TableBodyDataSkeleton";
import { allStyles } from "../../allStyles";
import { formatDate } from "../../helper/date_format_function";
import {
  Update_level_status,
  getAllUser,
  GetUserProfile_action,
  clear_get_profile_action,
} from "../../redux/actions/UserAction";
import {FcAlphabeticalSortingAz} from 'react-icons/fc';

const columns = [
  {
    id: "serialNumber",
    label: "S#",
    minWidth: 20,
    align: "center",
    sortAction: false,
  },
  {
    id: "first_name",
    label: "First Name",
    minWidth: 200,
    align: "center",
    sortAction: true,
  },
  {
    id: "last_name",
    label: "Last Name",
    minWidth: 180,
    align: "center",
    sortAction: true,
  },
  {
    id: "email",
    label: "Email",
    minWidth: 150,
    align: "center",
    sortAction: true,
  },
  {
    id: "level_two_profile_status",
    label: "User Status",
    minWidth: 180,
    align: "center",
    sortAction: true,
  },
  {
    id: "Actions",
    label: "Action",
    minWidth: 150,
    align: "center",
    sortAction: false,
  },
];

export const User = () => {
  const [SelecteData, SetSelectedData] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [orderData, setOrderData] = useState("ASC");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedDataForEdit, setSelectedDataForEdit] = React.useState(null);
  const [profile_Modal, Set_Profile_modal] = useState(false);
  const accessToken = useSelector(
    (state) => state.userTokensReducer.tokens.accessToken
  );
  const eventsState = useSelector((state) => state.eventsReducer);
  console.log(eventsState,'user Data');
  const { get_Profile, isLoading_profile } = useSelector(
    (state) => state.eventsReducer
  );
  console.log(get_Profile);
  const sortingColumns = (col) => {
    if (col === "Date") {
      if (orderData === "1 - 9") {
        const sorted = [...rows].sort((a, b) => (a[col] < b[col] ? 1 : -1));
        setRows([...sorted]);
        setOrderData("9 - 1");
      } else if (orderData === "9 - 1") {
        const sorted = [...rows].sort((a, b) => (a[col] > b[col] ? 1 : -1));
        setRows([...sorted]);
        setOrderData("1 - 9");
      } else {
        const sorted = [...rows].sort((a, b) => (a[col] > b[col] ? 1 : -1));
        setRows([...sorted]);
        setOrderData("1 - 9");
      }
    } else {
      if (orderData === "ASC") {
        const sorted = [...rows].sort((a, b) =>
          a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
        );
        setRows([...sorted]);
        setOrderData("DSC");
      } else if (orderData === "DSC") {
        const sorted = [...rows].sort((a, b) =>
          a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
        );
        setRows([...sorted]);
        setOrderData("ASC");
      } else {
        const sorted = [...rows].sort((a, b) =>
          a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
        );
        setRows([...sorted]);
        setOrderData("ASC");
      }
    }
  };

  useEffect(() => {
    setLoading(eventsState.isLoading);
    setRows(eventsState.events);
  }, []);

  useEffect(() => {
    setLoading(eventsState.isLoading);
    setRows(eventsState.events);
  }, [eventsState]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const addHandle = () => {
    setOpen(true);
  };

  // const refreshHandle = () => {
  //   getAllUser(dispatch, accessToken, navigate);
  //   Update_level_status(dispatch, accessToken, navigate);
  // };

  const HandleProfile = () => {
    Set_Profile_modal(false);
  };

  return (
    <>
      <Box>
        <Stack
          sx={{ px: 1, mb: 2 }}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack>
            <Typography variant="h5">Users</Typography>
          </Stack>
          <Stack spacing={3} direction="row" alignItems="center">
            <FormControl style={{ width: "200px" }} fullWidth>
              <InputLabel id="demo-simple-select-label">Select User Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Select User Status"
                  onChange={(e) => {
                    getAllUser(dispatch, accessToken, navigate, e.target.value);
                  }}
                >
                <MenuItem value={""}>All</MenuItem>
                <MenuItem value={"valid"}>valid</MenuItem>
                <MenuItem value={"invalid"}>inValid</MenuItem>
                <MenuItem value={"none"}>None</MenuItem>
              </Select>
            </FormControl>
            {/* <RefressButton disabled={loading} onClickHandle={refreshHandle} /> */}
          </Stack>
        </Stack>
        <Stack>
          <Paper sx={{ width: "100%" }}>
            <TableContainer sx={allStyles.tableHeight}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns?.map((column, i) => (
                      <TableCell
                        key={i}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.sortAction ? (
                          <Button
                            sx={allStyles.actionsButtons}
                            onClick={() => sortingColumns(column.id)}
                          >
                            {column.label}
                          <FcAlphabeticalSortingAz size={20} style={{marginLeft:"15px"}}/>

                          </Button>
                        ) : (
                          column.label
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                {loading ? (
                  <TableBodyDataSkeleton
                    numberOfColumns={columns.length}
                    imagePreview={true}
                    editAction={true}
                    deleteAction={true}
                  />
                ) : rows.length !== 0 ? (
                  <TableBody>
                    {rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      ?.map((row, i) => {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                            {columns.map((column, ind) => {
                              const value = row[column.id];
                              return column.id ===
                                "level_two_profile_status" ? (
                                row.level_two_profile_status === "none" ? (
                                  <TableCell key={ind} align={column.align}>
                                    ---
                                  </TableCell>
                                ) : column.id === "level_two_profile_status" ? (
                                  <TableCell key={ind} align={column.align}>
                                    <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label" style={{color: value === 'valid' ? "green" : 'red'}}>{value}</InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          label={value}
                                          variant="outlined"
                                          value={value}
                                            inputProps={{
                                              id: "uncontrolled-native",
                                            }}
                                            sx={{
                                              // backgroundColor: 'red',
                                              color: value === 'valid' ? "green" : 'red',
                                              '& .MuiSelect-iconOutlined': {
                                                color: value === 'valid' ? "green" : 'red',
                                              },
                                            }}
                                          onChange={(e) => {
                                            Update_level_status( accessToken, dispatch, navigate,  { id: row.id,  level_two_profile_status:    e.target.value,
                                              }
                                            );
                                          }}>
            
                                        <MenuItem style={{backgroundColor:"lime",color:"white"}} value="valid">Valid</MenuItem>
                                        <MenuItem style={{backgroundColor:"red",color:"white"}} value="invalid">InValid</MenuItem>
                                      </Select>

                                      {/* <NativeSelect
                                      sx={{backgroundColor:value === "valid" ? "green" : "red",color:"white"}}
                                      variant="standard"
                                        onChange={(e) => {
                                          Update_level_status( accessToken, dispatch, navigate,  { id: row.id,  level_two_profile_status:    e.target.value,
                                            }
                                          );
                                        }}
                                        value={value}
                                        inputProps={{
                                          id: "uncontrolled-native",
                                        }}
                                      >
                                        {console.log(value,"=========")}
                                        <option style={{backgroundColor:"lime",color:"white"}} value="valid">Valid</option>
                                        <option style={{backgroundColor:"red",color:"white"}} value="invalid">InValid</option>
                                      </NativeSelect> */}
                                    </FormControl>
                                  </TableCell>
                                ) : (
                                  <TableCell key={ind} align={column.align}>
                                    --- Not Set Yet ---
                                  </TableCell>
                                )
                              ) : column.id === "Date" ? (
                                <TableCell key={ind} align={column.align}>
                                  {value ? formatDate(value) : "--"}
                                </TableCell>
                              ) : column.id === "serialNumber" ? (
                                <TableCell key={ind} align={column.align}>
                                  {page < 1
                                    ? i + 1
                                    : page * rowsPerPage + (i + 1)}
                                </TableCell>
                              ) : column.id === "Actions" ? (
                                <TableCell key={ind} align={column.align}>
                                  <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="center"
                                  >
                                    <IconButton
                                      title="View Profile"
                                      onClick={() => {
                                        clear_get_profile_action(dispatch);
                                        // Set_Profile_modal(true);
                                        GetUserProfile_action(
                                          accessToken,
                                          dispatch,
                                          navigate,
                                          row.id
                                        );
                                        navigate(`/profile/${row.id}`);
                                      }}
                                      aria-label="edit"
                                    >
                                      <AiOutlineUser color="#2f6deb" />
                                    </IconButton>
                                  </Stack>
                                </TableCell>
                              ) : (
                                <TableCell key={ind} align={column.align}>
                                  {value}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                ) : (
                  <NoDataSetMessage lengthOfColumns={columns.length} />
                )}
              </Table>
            </TableContainer>
            {loading ? (
              <PaginationSkeleton />
            ) : (
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Paper>
        </Stack>

        <ToastContainer />
      </Box>
    </>
  );
};
