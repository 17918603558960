import {
  UpdateLevelStatus,
  getAllUserApi,
  GetUserProfile_api,
} from "../../api/UserApi";
import {
  displayErrorToast,
  displaySuccessToast,
} from "../../helper/toast_notification_function";

import {
  saveEvents,
  startGettingEventsData,
  GetUserProfile,
  ClearUserProfile,
} from "../constants/events_constants";

export const getAllUser = (dispatch, token, navigate, param) => {
  dispatch({
    type: startGettingEventsData,
  });
  getAllUserApi(token, dispatch, navigate, param).then((e) => {
    return dispatch({
      type: saveEvents,
      payload: e.data.data ? e.data.data : [],
    });
  });
};
export const GetUserProfile_action = (token, dispatch, navigate, param) => {
  GetUserProfile_api(token, dispatch, navigate, param)
    .then((res) => {
      dispatch({
        type: GetUserProfile,
        payload: res.data.data[0],
      });
    })
    .catch((error) => {
      console.log(error);
    });
};
export const clear_get_profile_action = (dispatch) => {
  dispatch({ type: ClearUserProfile });
};

export const Update_level_status = (token, dispatch, navigate, param) => {
  UpdateLevelStatus(token, dispatch, navigate, param)
    .then((res) => {
      console.log("success");
      getAllUser(dispatch, token, navigate, "");
      displaySuccessToast("level status updated");
    })
    .catch((error) => {
      console.log("fail");
      displayErrorToast(error);
    });
};
